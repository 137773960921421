<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-2">
          <h3>
            การขาย
          </h3>
          <div @click="openModalCheck">
            <b-button
              variant="danger"
              class="btn-icon rounded-circle shopping"
              style="box-shadow: 3px 3px 4px 4px #ccc;"
              @click="setPageModal(0), setdataOrder()"
            >
              <div class="d-flex seticonwithp">
                <feather-icon
                  class="setsizeicon"
                  icon="ShoppingCartIcon"
                />
                <p style="margin-top: -5px;    margin-left: -8px;">
                  +{{ sumItemShopping }}
                </p>
              </div>
            </b-button>
          </div>
        </div>
        <div
          class="custom-search d-flex align-items-center justify-content-between mb-1"
        >
          <b-form-group style="    margin-bottom: 0rem;">
            <div class="d-flex align-items-center">
              <label class="mr-1">ค้นหา</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="ค้นหา"
                type="text"
                class="d-inline-block mr-1"
              />
            </div>
          </b-form-group>
        </div>
        <div
          v-if="rows === 0"
          class="col-md-12 col-sm-6 col-xl-12 text-center"
        >
          <feather-icon
            class="empty-cart"
            icon="ShoppingCartIcon"
          />
          <h4 class="empty-font">
            ไม่พบข้อมูลสินค้า
          </h4>
        </div>
        <div
          v-if="rows > 0"
          class="row mt-2"
        >
          <div
            v-for="(data, index) of items.slice(
              (currentPage - 1) * perPage,
              (currentPage - 1) * perPage + perPage
            )"
            :key="index"
            class="col-12 col-sm-6 col-xl-4"
          >
            <div class="card carditem">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 text-center">
                    <b-img
                      thumbnail
                      fluid
                      :src="baseURL + data.product.pic"
                      alt="Image 1"
                      style="width:100%;max-width: 80px;"
                      @error="setAltImg"
                    />
                    <p class="mt-1">
                      {{ data.product.code }}
                    </p>
                  </div>
                  <div class="col-7">
                    <div
                      class="row"
                      style="margin-bottom: -8px;"
                    >
                      <div class="col-6 nonepading">
                        <p class="text-truncate">
                          ชื่อสินค้า
                        </p>
                      </div>
                      <div class="col-6 nonepading">
                        <p class="text-truncate">
                          {{ data.product.name }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row"
                      style="margin-bottom: 7px;"
                    >
                      <div class="col-6 nonepading align-self-center">
                        <p>
                          คลัง
                        </p>
                      </div>
                      <div class="col-6 nonepading">
                        <b-form-select
                          v-model="setInputs[data.index].inputs.warehouse"
                          :options="setInputs[data.index].warehouse"
                          @change="warehouseChange(data.index)"
                        />
                      </div>
                    </div>
                    <div
                      class="row"
                      style="margin-bottom: -9px;"
                    >
                      <div class="col-6 nonepading">
                        <p class="text-truncate">
                          คงเหลือ
                        </p>
                      </div>
                      <div class="col-6 nonepading">
                        <div class="d-flex">
                          <p class="fSRP mr-1">
                            {{ data.amount.$numberDecimal | toAmount }}
                          </p>
                          <p>
                            ชิ้น
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      style="margin-bottom: -5px;"
                    >
                      <div class="col-3 col-sm-4 nonepading">
                        <p class="text-truncate">
                          ราคา
                        </p>
                      </div>
                      <div class="col-9 col-sm-8 nonepading text-center">
                        <div v-if="data.promotion.length > 0">
                          <div class="d-flex justify-content-end">
                            <p class="fSRP font-weight-bolder">
                              {{ [{price:data.product.price.$numberDecimal,percen:data.promotion[0].value.$numberDecimal}] | toPercen }}
                              <b-badge
                                style="margin-left:3px"
                                variant="danger"
                              >
                                {{ `${data.promotion[0].value.$numberDecimal} %` }}
                              </b-badge>
                            </p>
                          </div>
                          <p
                            class="fSRP"
                            style="margin-top: -10px;"
                          ><s>
                            {{ data.product.price.$numberDecimal | toCurrency }}</s>
                          </p>
                        </div>
                        <div v-else>
                          <p class="fSRP mr-1">
                            {{ data.product.price.$numberDecimal | toCurrency }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <b-input-group class="setSpinbutton">
                        <b-input-group-prepend>
                          <b-button
                            variant="outline-secondary"
                            class="py-0"
                            size="sm"
                            style="width: 35px;
  border: 1px solid #d8d6de !important;
      color: white;
    background-color: #EA5354;"
                            @click="valueAmountChange(1,data.index)"
                          >
                            <b-icon
                              icon="dash"
                              font-scale="1.6"
                              style="margin-left: -8px;"
                            />
                          </b-button>
                        </b-input-group-prepend>
                        <vue-numeric
                          v-model="setInputs[data.index].inputs.addQty"
                          separator=","
                          :max="data.amount.$numberDecimal*1"
                          class="text-center"
                          style="padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
        position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;"
                        />

                        <!-- here -->
                        <!-- <VueNumberFormat
                          v-model="data.inputs.addQty"
                          @change="(e) => {
                            console.log(e)
                          }"

                        /> -->

                        <!-- <b-form-input
                          v-model="input.amount"
                          type="number"
                          min="0"
                          max="10000"
                          class="border-secondary text-center"
                          style="padding: 0 !important;border: 1px solid #d8d6de !important;"
                          number
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          :readonly="statusPage==='แก้ไข'? true:false"
                        /> -->
                        <b-input-group-append>
                          <b-button
                            variant="outline-dark"
                            class="py-0"
                            size="sm"
                            style="width: 35px;
  border: 1px solid #d8d6de !important;
      color: white;
    background-color: #29C76F;"
                            @click="valueAmountChange(-1,data.index)"
                          >
                            <b-icon
                              icon="plus"
                              font-scale="1.6"
                              style="margin-left: -8px;"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>

                      <!-- <div class="col-12">
                        <b-form-spinbutton
                          id="demo-sb"
                          v-model="data.addQty"
                          class="spin-cus"
                          min="0"
                          max="1000000"
                          @input="setSumTotalQtyItem()"
                        />
                      </div> -->
                    </div>
                    <div
                      class="row"
                      style="    margin-top: 8px;"
                    >
                      <div class="col-12">
                        <div class="d-flex justify-content-center">
                          <b-button
                            variant="outline-dark"
                            class="border-primary"
                            size="sm"
                            style="width: 35px;
    padding: 0px;
    height: 25px;margin-right: 3px;"
                            @click="valueAmountChange(-10,data.index)"
                          >10
                          </b-button>
                          <b-button
                            variant="outline-dark"
                            class="border-primary"
                            size="sm"
                            style="width: 35px;
    padding: 0px;
    height: 25px;margin-right: 3px;"
                            @click="valueAmountChange(-50,data.index)"
                          >50
                          </b-button>
                          <b-button
                            variant="outline-dark"
                            class="border-primary"
                            size="sm"
                            style="width: 35px;
    padding: 0px;
    height: 25px;"
                            @click="valueAmountChange(-100,data.index)"
                          >100
                          </b-button>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-pagination
              v-model="currentPage"
              hide-goto-end-buttons
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="justify-content-end"
            />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="my-modal"
      v-model="IsopenModal"
      hide-footer
      header-bg-variant="primary"
      size="lg"
    >
      <template v-if="modalPage == 0">
        <div class="scrollable-card">
          <div
            v-for="(data, index) of dataOrder"
            :key="index"
            class="row"
            style="margin-bottom: -30px;"
          >
            <div class="col-12">
              <div
                class="card carditem"
                style="height: 75%;"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-3 text-center">
                      <b-img
                        thumbnail
                        fluid
                        :src="baseURL + data.items.product.pic"
                        alt="Image 1"
                        style="width:100%;max-width: 80px;"
                        @error="setAltImg"
                      />
                      <p class="mt fSRP">
                        {{ data.items.product.code }}
                      </p>
                    </div>
                    <div class="col-9">
                      <div class="row mrcol">
                        <div class="col-4 nonepading">
                          <p class="text-truncate">
                            ชื่อสินค้า
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <div class="d-flex justify-content-between">
                            <p class="text-truncate mr-1 text-left">
                              {{ data.items.product.name }}
                            </p>
                            <div class="settrash">
                              <b-button
                                variant="danger"
                                @click="onDeleteOrderList(index)"
                              >
                                <feather-icon
                                  class="mgfeather text-center"
                                  icon="TrashIcon"
                                />
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mrcol">
                        <div class="col-4 nonepading ">
                          <p>
                            คลัง
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <p>{{ data.wareHouseName }}</p>
                        </div>
                      </div>
                      <div class="row mrcol">
                        <div class="col-4 nonepading">
                          <p class="text-truncate">
                            จำนวน
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <div class="d-flex">
                            <div v-if="data.items.promotion.length > 0">
                              <p class="fSRP">
                                {{ data.inputs.addQty | toAmount }} &nbsp;/&nbsp; {{ data.items.priceProduct | toCurrency }}
                                <b-badge
                                  style="margin-left:3px"
                                  variant="danger"
                                >
                                  {{ `${data.items.promotion[0].value.$numberDecimal} %` }}
                                </b-badge>
                                <span
                                  v-if="sizepagee !== 'xs'"
                                  class="ml-1"
                                >บาท</span>
                              </p>

                            </div>
                            <div v-else>
                              <p class="fSRP mr-1">
                                {{ data.inputs.addQty | toAmount }} &nbsp;/&nbsp; {{ data.items.priceProduct | toCurrency }}&nbsp;บาท
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="row mrcol">
                        <div class="col-4 nonepading">
                          <p class="text-truncate">
                            ราคารวม
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <div class="d-flex">
                            <p class="fSRP mr-1">
                              {{ data.inputs.addQty * data.items.priceProduct | toCurrency }}
                            </p>
                            <p>
                              บาท
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card is_sticky"
            style="height:195px;
"
          >
            <div class="card-body">
              <div class="row justify-content-end">
                <div
                  class="col-9 col-sm-6 col-lg-4 text-right"
                  :style="
                    NameCustomerOrder == '' ? '    margin-bottom: 27px;' : ''
                  "
                >
                  <p>ราคารวม &nbsp;{{ sumtotalOrder | toCurrency }} &nbsp;บาท</p>
                  <div
                    v-if="NameCustomerOrder != ''"
                    class="d-flex justify-content-end"
                  >
                    <feather-icon
                      class="text-center css-1sns0wc mr-1"
                      icon="RotateCwIcon"
                      style="margin-buto;    margin-top: 4px; cursor: pointer;"
                      @click="resetNameCus"
                    />
                    <p class="text-truncate">
                      ลูกค้า &nbsp;{{ NameCustomerOrder }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-end align-items-center mb-1">
                    <span class="mr-1">ช่องทาง</span>
                    <b-form-select
                      v-model="channel"
                      style="width:120px"
                      :options="Datachannel"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-1 d-flex justify-content-center">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="confirmOrder()"
                >
                  {{ nameButtonOrder }}
                </b-button>
                <b-button
                  variant="danger"
                  style="width:95px"
                  @click="closeModal()"
                >
                  ย้อนกลับ
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="modalPage == 1">
        <div class="mt-1">
          <addCustomer
            :enter="enter"
            :status="'U'"
            @setPageModal="setPageModal"
            @loadCustomer="loadCustomer"
          />
        </div>
      </template>
      <template v-if="modalPage == 2">
        <div class="row">
          <div class="col-12">
            <div
              class="custom-search d-flex align-items-center justify-content-between mb-1 mt-2"
            >
              <b-form-group style="    margin-bottom: 0rem;">
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchCustomer"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block mr-1"
                  />
                </div>
              </b-form-group>
              <b-form-group
                class="DisplayHide"
                style="margin-bottom: 0rem;"
              >
                <div class="d-flex align-items-center">
                  <label class="mr-1">ประเภท</label>
                  <b-form-select
                    v-model="input.typeCustomer"
                    :options="typeCustomer"
                    class="d-inline-block mr-1"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="mt-1 mb-1">
              <b-button
                variant="primary"
                style="height: 38px;"
                @click="setPageModal(1)"
              >
                เพิ่ม
              </b-button>
            </div>
            <b-table
              striped
              hover
              responsive
              class="type-relative"
              :per-page="inputsnodeCustomer.perPage"
              :current-page="inputsnodeCustomer.currentPage"
              :items="listDataCustomer"
              :fields="fieldsCustomerTable"
              :filter="searchCustomer"
              @filtered="onFiltered"
              @row-clicked="setModal"
            >
              <template #cell(name)="data">
                {{ data.item.firstName }} {{ data.item.lastName }}
              </template>
              <template #cell(type)="data">
                {{ data.item.type==='U'?'ลูกค้าทั่วไป':'ตัวแทน' }}
              </template>
            </b-table>
            <b-card-body
              class="d-flex justify-content-between flex-wrap pt-0 mt-2 mb-2"
            >
              <b-form-group
                label="Per Page"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="inputsnodeCustomer.perPage"
                  size="sm"
                  inline
                  :options="inputsnodeCustomer.pageOptions"
                />
              </b-form-group>

              <div>
                <b-pagination
                  v-model="inputsnodeCustomer.currentPage"
                  :total-rows="inputsnodeCustomer.totalRows"
                  :per-page="inputsnodeCustomer.perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BImg,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSpinbutton,
  BPagination,
  BButton,
  BModal,
  BTable,
  BCardBody
  , BInputGroupPrepend, BInputGroup, BInputGroupAppend, BIcon, BIconDash, BIconPlus, BBadge
} from 'bootstrap-vue'
import VueNumeric from 'vue-numeric'
import addCustomer from '@/views/components/production/add_customer.vue'
import PlaceHolder from '@/assets/images/production/img_default.png'

export default defineComponent({
  name: 'sales_order',
  components: {
    BImg,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSpinbutton,
    BPagination,
    BButton,
    BModal,
    BTable,
    BCardBody,
    addCustomer,
    VueNumeric,
    ToastificationContent,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BIcon,
    BIconDash,
    BIconPlus,
    BBadge

  },
  computed: {
    sumtotalOrder () {
      let sum = 0
      this.dataOrder.forEach(data => {
        sum += data.inputs.addQty * (data.items.priceProduct)
      })
      return sum
    },
    nameButtonOrder () {
      return this.NameCustomerOrder === '' ? 'เลือกลูกค้า' : 'ยืนยัน'
    },
    listCustomer () {
      return this.$store.state.customer.listCustomer
    },
    checkType () {
      return this.$store.state.customer.listCustomer.filter(x => x.type === this.input.typeCustomer)
    },
    // datasItem(){
    //   return this.$store.state.production.dataProductOrder.length > 0 ? this.$store.state.production.dataProductOrder:[]
    // }
    sizepagee () {
      return this.$store.getters['app/currentBreakPoint']
    }

  },
  watch: {

    searchTerm (Val) {
      this.items = this.$store.state.production.dataProductOrder.filter(
        data => data.product.code.includes(Val) || data.product.name.includes(Val)
      )
      this.rows = this.items.length
      this.currentPage = 1
    },
    input: {
      handler (val) {}
    },
    'input.typeCustomer': {
      handler (val) {
        if (val === '') {
          this.listDataCustomer = this.listCustomer
          this.inputsnodeCustomer.totalRows = this.listCustomer.length
          this.inputsnodeCustomer.currentPage = 1
          return
        }
        this.listDataCustomer = this.checkType
        this.inputsnodeCustomer.totalRows = this.checkType.length
        this.inputsnodeCustomer.currentPage = 1
      }
    },
    '$store.state.production.dataProductOrder': {
      handler (val) {
        this.items = val
        this.rows = this.items.length
        this.setInputs = this.$store.state.production.datas
      }
    },
    setInputs: {
      handler (newVal) {
        this.setSumTotalQtyItem()
      },
      deep: true
    }
  }, // END Watch
  methods: {
    setAltImg (e) {
      console.log('alt')
      e.target.src = PlaceHolder
    },
    loaderChannel () {
      this.$store.dispatch('setting/getAllChannel').then(result => {
        console.log(result)
        if (result.data.items.length > 0) {
          this.channel = result.data.items[0]._id
          result.data.items.forEach(item => {
            this.Datachannel.push({ text: item.name, value: item._id })
          })
        // this.$refs.channelRules.reset()
        }
      })
    },
    async loader () {
      await this.loadWarehouse()
      await this.loadOrder()
      this.loaderChannel()
    },
    async loadOrder () {
      await this.$store.dispatch('production/getProductOrderAll', this.datasWareHous).then(result => {
      })
    },
    async loadWarehouse () {
      await this.$store.dispatch('warehouse/getWareHouse', this.input).then(result => {
        this.datasWareHous = []
        result.data.items.forEach(data => {
          this.datasWareHous.push({ value: data._id, text: data.name })
        })
      })
    },
    onClickAddAmount (val, index) {
      this.setInputs[index].inputs.addQty += val
    },
    onConfirmOrder () {
      const setDataApi = {
        listProduct: [],
        customerId: this.IDCustomerOrder,
        employerId: this.userData.id,
        paymentId: this.channel
      }
      this.dataOrder.forEach(data => {
        setDataApi.listProduct.push({
          id: data.items.product._id,
          amount: data.inputs.addQty,
          price: parseFloat(data.items.product.price.$numberDecimal),
          warehouseId: data.inputs.warehouse,
          type: '',
          // eslint-disable-next-line radix
          promotion: data.items.promotion.length === 0 ? 0 : parseInt(data.items.promotion[0].value.$numberDecimal)

        })
      })

      this.$store.dispatch('order/addOrder', setDataApi).then(result => {
        if (result.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: result.data.message
            }
          })
          // this.loader()
          // this.closeModal()
          // this.resetNameCus()
          // this.loadCustomer()
          this.$router.push({ path: `/orderpayment/${result.data.id}/add` })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: result.data.message
            }
          })
        }
      })
    },
    warehouseChange (index) {
      this.$store.dispatch('production/getStockWhs', { index, warehouseId: this.setInputs[this.items[index].index].inputs.warehouse, productId: this.items[index].product._id }).then(res => {
        this.setInputs[this.items[index].index].inputs.addQty = 0
      })
    },
    valueAmountChange (val, index) {
      if (this.setInputs[index].inputs.addQty >= 0) {
        this.setInputs[index].inputs.addQty -= val
        if (this.setInputs[index].inputs.addQty <= 0) this.setInputs[index].inputs.addQty = 0
        if (this.setInputs[index].inputs.addQty >= (this.items[index].amount.$numberDecimal * 1)) this.setInputs[index].inputs.addQty = this.items[index].amount.$numberDecimal * 1
      }
    },
    loadCustomer () {
      this.$store.dispatch('customer/getCustomer')
        .then(response => {
          this.listDataCustomer = response.data.items
          this.inputsnodeCustomer.totalRows = response.data.items.length
        }).catch(error => {
          console.log(error)
        })
    },
    openModalCheck () {
      let textalert = ''
      const dataMorethen1 = this.setInputs.filter(x => x.inputs.addQty > 0)
      if (dataMorethen1.length === 0) textalert = 'โปรดทำการเลือกสินค้า'

      if (textalert !== '') {
        this.$swal.fire({
          icon: 'info',
          text: textalert
        })
        return
      }
      this.IsopenModal = true
    },
    setdataOrder () {
      this.dataOrder = []
      this.setInputs.forEach((data, index) => {
        if (data.inputs.addQty > 0) {
          const item = this.$store.state.production.dataProductOrder.filter(x => x.index === index)
          if (item.length > 0) {
            const Whs = data.warehouse.filter(x => x.value === data.inputs.warehouse)

            this.dataOrder.push({
              items: item[0],
              inputs: data.inputs,
              wareHouseName: Whs[0].text
            })
          }
        }
      })
    },
    onDeleteOrderList (index) {
      this.setInputs[this.dataOrder[index].items.index].inputs.addQty = 0
      this.dataOrder = this.dataOrder.filter(x => x.items.product.code !== this.dataOrder[index].items.product.code)
      if (this.dataOrder.length == 0) this.closeModal()
    },
    setSumTotalQtyItem () {
      this.sumItemShopping = 0
      this.setInputs.forEach(data => {
        if (data.inputs.addQty > 0) {
          this.sumItemShopping += 1
        }
      })
    },
    setModal (item, index, event) {
      this.NameCustomerOrder = `${item.firstName} ${item.lastName}`
      this.IDCustomerOrder = item._id

      this.setPageModal(0)
    },
    setPageModal (index) {
      this.modalPage = index
    },
    onFiltered (filteredItems) {
      this.inputsnodeCustomer.totalRows = filteredItems.length
      this.inputsnodeCustomer.currentPage = 1
    },
    confirmOrder () {
      if (this.NameCustomerOrder === '') this.setPageModal(2); else this.onConfirmOrder()
    },
    resetNameCus () {
      this.NameCustomerOrder = ''
      this.IDCustomerOrder = ''
    },
    closeModal () {
      this.IsopenModal = false
    },
    onAddOrderButton () {
      const item = {
        listProduct: this.dataOrder,
        customerId: this.customerId,
        employerId: this.userData.id
      }
      this.$store.dispatch('order/add', item)
        .then(response => {
          if (response.data.success) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ลูกค้า',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ลูกค้า',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message
              }
            })
          }
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ลูกค้า',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: err.message
            }
          })
        })
    }
  },
  created () {
    this.loader()
    this.loadCustomer()
    this.item = this.datas
  },
  data () {
    return {
      baseURL: this.$baseURL,
      userData: JSON.parse(localStorage.getItem('userData')),
      input: { typeCustomer: '' },
      setInputs: [],
      items: [],
      datasWareHouse: [],
      enter: 'modal',
      IsopenModal: false,
      searchTerm: '',
      currentPage: 1,
      perPage: 6,
      rows: 0,
      sumItemShopping: 0,
      modalPage: 0,
      searchCustomer: '',
      listDataCustomer: null,
      customerId: '',
      dataOrder: [],
      NameCustomerOrder: '',
      IDCustomerOrder: '',
      Datachannel: [],
      channel: '',
      inputsnodeCustomer: {
        perPage: 3,
        pageOptions: [3, 5, 10],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      },

      typeCustomer: [
        { value: '', text: '-ทั้งหมด-' },
        { value: 'V', text: 'ตัวแทน' },
        { value: 'U', text: 'ลูกค้าทั่วไป' }
      ],
      dataCustomer: [],
      fieldsCustomerTable: [
        {
          key: 'code',
          label: 'รหัสลูกค้า',
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: 'width:30%;min-width: 160px;',
          sortable: true
        },
        {
          key: 'name',
          label: 'ชื่อลูกค้า',
          tdClass: 'text-truncate',
          thStyle: 'width:40%;min-width: 200px;'
        },
        { key: 'telephoneNumber', label: 'เบอร์โทร', thStyle: 'width:15%;min-width:125px' },
        { key: 'type', label: 'ประเภท', thStyle: 'width:15%;min-width: 150px;' }
      ]
    }
  }
})
</script>
<style scoped>
.empty-cart {
  width:100px;
  height:100px;
  color:gray;
  opacity: 0.3;
}
.empty-font {
  font-size:40px;
  color:gray;
  opacity: 0.3;
}
.carditem {
  box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.16)  !important;
  -webkit-box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.16) !important;
  -moz-box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.16) !important;
}
#modal-center___BV_modal_body_ {
  padding: 0px;
}
.modal-open .modal {
  overflow-y:unset !important;
}
.scrollable-card {
  overflow-y: auto !important;
}
.nonepading {
  padding: 0px;
}
.fSRP {
  font-size: auto;
}
.mrcol {
  margin-bottom: -10px;
}
.is_sticky {
  height: 100px;
  bottom: 0;
  position: sticky;
}

@media only screen and (max-width: 600px) {
  .shopping {
    width: 70px;
    height: 70px;
  }
  .mgfeather {
    margin-left: -6px;
  }
  .settrash button {
    width: 10px;
  }
  .settrash {
    margin-top: -12px;
  }
  .DisplayHide {
    display: none;
  }
  .setsizeicon {
    min-width: 20px;
    min-height: 20px;
    margin-right: 4px;
    margin-top: 7px;
  }
  .seticonwithp {
    margin-left: 6px;
  }
}
@media only screen and (min-width: 600px) {
  .shopping {
    width: 90px;
    height: 90px;
    /* margin-left: -18px; */
  }
  .setsizeicon {
    min-width: 25px;
    min-height: 25px;
    margin-right: 4px;
    margin-top: 7px;
    /* margin-left: -10px; */
  }
  .seticonwithp {
    margin-left: 15px;
  }
  .settrash {
    margin-right: 20px;
  }
}
</style>
<style>
.spin-cus button:last-child {
  background-color: #29c76f !important;
}
.spin-cus button:first-child {
  background-color: #ea5354 !important;
}
</style>
